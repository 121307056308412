import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header>
    <div className="container mx-auto p-4 lg:py-16">
      <h1 className="text-3xl lg:text-5xl font-sans font-bold">
        Paweł Woś
      </h1>
      <h2 className="text-xl">A full stack developer</h2>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
